import React from "react"

const useClientSide = (callback: () => void) => {
  const [isClientSide, setIsClientSide] = React.useState<boolean>(false)

  React.useEffect(() => {
    const hasWindowObject: boolean = typeof window !== "undefined"

    if (hasWindowObject) callback()
    setIsClientSide(hasWindowObject)
  }, [])

  return isClientSide
}

export { useClientSide }
