import { useHideProductsModal, useIsVisibleProductsModal } from "@app/features/products/hooks/products-modal"
import { restoreScroll, useRouteChangeStart } from "@app/utils"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

const productsModalActions = (PassedComponent, modalName: PRODUCTS_MODAL_TYPES) => {
  const WrappedComponent = () => {
    const $isVisible = useIsVisibleProductsModal(modalName)
    const $hide = useHideProductsModal()

    useRouteChangeStart(() => $isVisible && restoreScroll())

    return <PassedComponent isVisible={$isVisible} hide={$hide} />
  }

  return WrappedComponent
}

export { productsModalActions }
