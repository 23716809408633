const customGroups = (groups, generalHandle, customHandle, startPosition) => {
  const photoBookCustomer = groups.filter((group) => group.handle === generalHandle)
  const { handle, ...generalProperties } = photoBookCustomer[0]
  const newGroup = { handle: customHandle, ...generalProperties }
  const newGroups = [...groups]

  newGroups.splice(startPosition, 0, newGroup)

  return newGroups
}

export { customGroups }
