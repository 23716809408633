import { useNotificationStore } from "features/notification/store"

const showNotificationSelector = (state) => state.showNotification
const hideNotificationSelector = (state) => state.hideNotification
const isNotificationVisibleSelector = (state) => state.isNotificationVisible
const notificationSettingsSelector = (state) => state.notificationSettings

function useNotification() {
  const $showNotification = useNotificationStore(showNotificationSelector)
  const $hideNotification = useNotificationStore(hideNotificationSelector)
  const $isNotificationVisible = useNotificationStore(isNotificationVisibleSelector)
  const $notificationSettings = useNotificationStore(notificationSettingsSelector)

  return {
    $showNotification,
    $hideNotification,
    $isNotificationVisible,
    $notificationSettings,
  }
}

export { useNotification }
