import React from "react"

const SplitTestingContext = React.createContext<any | null>(null)

const SplitTestingProvider = ({ children, value }) => {
  const [splitTesting, setSplitTesting] = React.useState(value)

  return (
    <SplitTestingContext.Provider value={{ splitTesting, setSplitTesting }}>{children}</SplitTestingContext.Provider>
  )
}

function hasSplitTestingContext() {
  const splitTestingContext = React.useContext(SplitTestingContext)
  return splitTestingContext?.splitTesting
}

function useSplitTesting() {
  return React.useContext(SplitTestingContext)
}

export { SplitTestingProvider, hasSplitTestingContext, useSplitTesting }
