import create, { State } from "zustand"

interface SelectionStatusStore extends State {
  isInitialSelectionCompleted: boolean
  setInitialSelectionStatus: (status: boolean) => void
}

const useSelectionStatusStore = create<SelectionStatusStore>((set) => ({
  isInitialSelectionCompleted: false,
  setInitialSelectionStatus: (status) => set({ isInitialSelectionCompleted: status }),
}))

const isInitialSelectionCompletedSelector = (state: SelectionStatusStore) => state.isInitialSelectionCompleted
const setInitialSelectionStatusSelector = (state: SelectionStatusStore) => state.setInitialSelectionStatus

function useSelectionStatus() {
  const isInitialSelectionCompleted = useSelectionStatusStore(isInitialSelectionCompletedSelector)
  const setInitialSelectionStatus = useSelectionStatusStore(setInitialSelectionStatusSelector)

  return { isInitialSelectionCompleted, setInitialSelectionStatus }
}

export { useSelectionStatus }
