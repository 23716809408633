import React from "react"
import { CSSTransition } from "react-transition-group"
import Link from "next/link"
import { useI18n } from "next-localization"
import cn from "clsx"

import { ADDED_TO_FAVORITES_NOTIFICATION_TYPE, LINK_COPIED_NOTIFICATION_TYPE } from "features/notification/constants"
import { useNotification } from "features/notification/store/hooks"
import { useRouteChange } from "utils"

import styles from "./notification.module.scss"

import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

function Notification({ spaceBottomEnabled = false }) {
  const { $isNotificationVisible, $notificationSettings, $hideNotification } = useNotification()

  const addedToFavoritesNotification = $notificationSettings.type === ADDED_TO_FAVORITES_NOTIFICATION_TYPE
  const linkCopiedNotification = $notificationSettings.type === LINK_COPIED_NOTIFICATION_TYPE

  const i18n = useI18n()

  useRouteChange($hideNotification)

  return (
    <CSSTransition
      in={$isNotificationVisible}
      timeout={{
        enter: 0,
        exit: 200,
      }}
      classNames={{
        enter: styles["enter"],
        enterDone: styles["enter-done"],
        exit: styles["exit"],
        exitActive: styles["exit-active"],
      }}
      unmountOnExit
    >
      <aside className={cn(styles["notification"], { [styles["bottom_space"]]: spaceBottomEnabled })}>
        {$notificationSettings.image && (
          <GoogleImage className={styles["image"]} src={$notificationSettings.image} />
          // <div className={styles["image"]} style={{ backgroundImage: `url(${$notificationSettings.image})` }}></div>
        )}

        {addedToFavoritesNotification && (
          <p className={styles["text"]}>
            <span>{i18n.t("notification.addedTo")} </span>
            <Link href={$notificationSettings.link}>
              <a className={styles["link"]}>{i18n.t("common.favorites")}</a>
            </Link>
          </p>
        )}

        {linkCopiedNotification && (
          <p className={cn(styles["text"], styles["text-center"])}>
            <span>{$notificationSettings.text}</span>
          </p>
        )}
      </aside>
    </CSSTransition>
  )
}

export { Notification }
