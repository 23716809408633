const getGalleryScenesWithPhotos = (scenes) => {
  return scenes?.reduce((acc, scene) => {
    const photos = scene.mediaFiles.filter((file) => file.type === "photo")
    if (photos.length > 0) {
      acc.push({
        id: scene.id,
        name: scene.name,
        photos: photos,
      })
    }
    return acc
  }, [])
}

export { getGalleryScenesWithPhotos }
