import React from "react"

import { useI18n } from "next-localization"

import { useGallery } from "@app/contexts"
import { useFavoritesList } from "@app/features/favorites/hooks"
import { useLimitModal } from "@app/features/user/stores"

import { Modal } from "ui/modal"
import { Button } from "ui/button"

import styles from "./limit-modal.module.scss"

function LimitModal() {
  const { isLimitModalVisible, hideLimitModal } = useLimitModal()
  const { gallery } = useGallery()
  const limitPhoto = gallery?.favListPhotoLimit

  const like = useFavoritesList({ isRedirectToFavoritesListPage: true })

  function handleClickButton() {
    like()
    hideLimitModal()
  }

  const i18n = useI18n()

  return (
    <Modal open={isLimitModalVisible} onClose={hideLimitModal} isMobileType={true}>
      <div className={styles["root"]}>
        <p className={styles["text"]}>{i18n.t("favoritesModal.description", { limitPhoto })}</p>
        <Button skin="secondary" onClick={handleClickButton} className={styles["button"]}>
          {i18n.t("favoritesModal.button")}
        </Button>
      </div>
    </Modal>
  )
}

export { LimitModal }
