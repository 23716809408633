import React from "react"
import { useI18n } from "next-localization"

import styles from "./empty.module.scss"

function Empty() {
  const i18n = useI18n()

  return <p className={styles["root"]}>{i18n.t("favoritesListPage.noPhotos")}</p>
}

export { Empty }
