import { useFavoriteListsStore } from "features/favorites/store"

const selector = (state) => state.setFavoriteLists

function useSetFavoriteLists() {
  const setFavoriteLists = useFavoriteListsStore(selector)

  return setFavoriteLists
}

export { useSetFavoriteLists }
