import React from "react"
import styles from "./products-container.module.scss"

type TProductsContainerProps = {
  children: React.ReactNode
}

/**
 * Компонент-обертка для списка товаров, задает необходимые отступы и размеры контейнера, применим для страниц галерей
 *
 * @component
 * @example
 * return (
 *   <ProductsContainer>
 *      <ProductGroupsList />
 *   </ProductsContainer>
 * )
 */
const ProductsContainer: React.FC<TProductsContainerProps> = ({ children }: TProductsContainerProps): JSX.Element => {
  return <div className={styles["root"]}>{children}</div>
}

export { ProductsContainer }
