import { useCallback, useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"
import { useI18n } from "next-localization"
import cn from "clsx"

import { useGetFavoritePhotos } from "@app/features/masonry/hooks"
import { useGetAllPhotos, usePhotoCarousel, useSetAllPhotos } from "@app/features/photo-carousel/store/hooks"

import { CommentButton } from "../comment-button"

const Masonry = dynamic(() => import("@app/features/masonry/components/masonry/masonry").then((mod) => mod.Masonry), {
  ssr: false,
})

import { useGallery } from "@app/contexts"
import { Empty } from "@app/features/favorites/components"
import { ActionBar } from "@app/features/masonry/components"
import { PhotoPreview } from "@app/features/masonry/components/photo-card/photo-preview"
import { PhotoCarousel } from "@app/features/photo-carousel/components"
import { ProductsContainer } from "@app/features/products/components/products-container"
import { ProductsSection } from "@app/features/products/components/products-section"
import { Container } from "@app/ui/container"

import { CompleteSelection } from "../complete-selection"

import styles from "./favorites.module.scss"

const Favorites = ({ favoritesListId, isDownloadEnabled, completedSelection }) => {
  const [isContentLoading, setIsContentLoading] = useState(true)
  const $favoritePhotos = useGetFavoritePhotos()
  const $setAllPhotos = useSetAllPhotos()
  const timerId = useRef(null)

  const { gallery } = useGallery()

  const { t } = useI18n()

  const isShowProductSection = gallery?.photoCount >= 20 && gallery.isPrintableProductsEnabled

  useEffect(() => {
    $setAllPhotos($favoritePhotos)
    setIsContentLoading(false)
  }, [$favoritePhotos, $setAllPhotos])

  const { $showPhotoCarousel, $setActivePhoto } = usePhotoCarousel()
  const $allMediaFiles = useGetAllPhotos()

  const handleMediaFilePreviewClick = useCallback(
    (mediaFile) => {
      const mediaFileIdex = $allMediaFiles.findIndex((item) => item.id === mediaFile.id)
      $setActivePhoto(mediaFileIdex)
      $showPhotoCarousel()
    },
    [$allMediaFiles, $setActivePhoto, $showPhotoCarousel]
  )

  const isPhotosExist = !!$favoritePhotos?.length

  return (
    <>
      <div
        className={cn(styles["root"], {
          [styles["grid"]]: isPhotosExist,
          [styles["empty"]]: !isPhotosExist,
        })}
      >
        {!isContentLoading && isPhotosExist && (
          <Container isFullWidth={true} className={styles["container"]}>
            <Masonry
              items={$favoritePhotos}
              getItemId={(item) => item.id}
              renderItem={(item) => (
                <PhotoPreview
                  photo={item}
                  onClick={() => handleMediaFilePreviewClick(item)}
                  renderOverlay={({ isHovered, photo: item, srcImage }) => (
                    <ActionBar
                      photo={item}
                      photoId={item.id}
                      photoById={item}
                      timerId={timerId}
                      visible={isHovered}
                      srcImage={srcImage}
                      isCanBeHidden={false}
                      isDownloadEnabled={isDownloadEnabled}
                      favoritesListId={favoritesListId}
                    />
                  )}
                />
              )}
            />
            {completedSelection && <CommentButton />}
            {!completedSelection && <CompleteSelection countPhotos={$favoritePhotos?.length} />}
          </Container>
        )}
        {!isContentLoading && (
          <PhotoCarousel
            type="favorites"
            favoritesListId={favoritesListId}
            isDownloadEnabled={isDownloadEnabled}
          ></PhotoCarousel>
        )}
        {!isContentLoading && !isPhotosExist && <Empty></Empty>}
      </div>
      {isShowProductSection && (
        <ProductsContainer>
          <ProductsSection
            title={t("productsModal.base.title")}
            description={t("productsModal.base.description")}
            onlyAvailable={false}
            position={"footer"}
          />
        </ProductsContainer>
      )}
    </>
  )
}

export { Favorites }
