import * as React from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { fetchFavoriteLists } from "features/favorites/requests"

import { useCommentsModal, useSelectionStatus } from "@app/features/favorites/store"

import { SimpleModal } from "@app/ui/modal"
import { Textarea } from "@app/ui/textarea"
import { Button } from "@app/ui/button"
import { useGallery, useGlobalState } from "@app/contexts"
import { sendCommentForFavoritesList, updateFavoritesList } from "@app/features/favorites/requests"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./comment-modal.module.scss"
import { useSetFavoriteLists } from "../../store/hooks"

export type TCommentModalProps = { favoritesListId: string }

const CommentModal = ({ favoritesListId }: TCommentModalProps) => {
  const i18n = useI18n()
  const [isSubmiting, setIsSubmiting] = React.useState(false)
  const [isSuccessfullySumbited, setIsSuccessfullySubmited] = React.useState(false)
  const [error, setError] = React.useState("")
  const errors = {
    empty: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationMessage"),
    limit: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationLimit"),
    default: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationDefault"),
  }
  const { gallery } = useGallery()

  const $setFavoriteLists = useSetFavoriteLists()

  const { isCommentModalVisible, hideCommentModal } = useCommentsModal()
  const { isInitialSelectionCompleted, setInitialSelectionStatus } = useSelectionStatus()
  const {
    userAgent: { isIos },
  } = useGlobalState()

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    setIsSubmiting(true)
    const comment = event.target["elements"].comment.value.trim()
    // const favoritesListId = router.query.slug as string

    if (isSubmiting) return

    if (!isInitialSelectionCompleted) {
      if (comment) {
        const response = await sendCommentForFavoritesList({ text: comment, favoritesListId })

        if (!response.success) {
          if (response.code === 400) {
            setError("limit")
          } else {
            setError("default")
          }

          setIsSubmiting(false)

          return
        }
      }

      await updateFavoritesList({
        key: "isInitialSelectionCompleted",
        value: true,
        favoritesListId,
      })

      const favoriteLists = await fetchFavoriteLists({ galleryId: gallery.id })
      $setFavoriteLists(favoriteLists)

      setIsSuccessfullySubmited(true)

      return
    } else if (isInitialSelectionCompleted && comment) {
      const response = await sendCommentForFavoritesList({ text: comment, favoritesListId })

      if (response.success) {
        setIsSuccessfullySubmited(true)
      } else {
        if (response.code === 400) {
          setError("limit")
        } else {
          setError("default")
        }

        setIsSubmiting(false)
      }

      return
    }

    if (isInitialSelectionCompleted && !comment) {
      setError("empty")
      setIsSubmiting(false)

      return
    }

    setIsSubmiting(false)
  }

  function handleCloseClick() {
    hideCommentModal()
    setIsSubmiting(false)
    setIsSuccessfullySubmited(false)
    setError("")

    if (isSuccessfullySumbited && !isInitialSelectionCompleted) {
      setInitialSelectionStatus(true)
    }
  }

  function handleFocus() {
    setError("")
  }

  return (
    <SimpleModal
      open={isCommentModalVisible}
      onClose={handleCloseClick}
      crossClassName={QA_CLASS_NAMES.favourites.feedbackClose}
    >
      <form onSubmit={handleSubmit} className={styles["form"]}>
        {!isSuccessfullySumbited && (
          <React.Fragment>
            <h3 className={styles["title"]}>
              {!isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.completeSelectionTitle")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentTitle")}
            </h3>
            <p className={styles["description"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionDescription")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentDescription")}
            </p>
            <Textarea
              placeholder={i18n.t("favoritesListPage.userCommentsModal.completeSelectionPlaceholder")}
              className={cx(
                styles["textarea"],
                error && styles["error"],
                {
                  [styles["scalable-area"]]: isIos,
                },
                QA_CLASS_NAMES.favourites.input
              )}
              disabled={isSubmiting}
              name="comment"
              onFocus={handleFocus}
            ></Textarea>
            {error && <p className={styles["error-message"]}>{errors[error]}</p>}
            <Button
              type="submit"
              loading={isSubmiting}
              className={cx(styles["button"], QA_CLASS_NAMES.favourites.send)}
            >
              {i18n.t("common.send")}
            </Button>
          </React.Fragment>
        )}

        {isSuccessfullySumbited && (
          <React.Fragment>
            <h3 className={styles["title"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionSuccessTitle")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentSuccessTitle")}
            </h3>
            <p className={styles["description"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionSuccessDescription")}
              {isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.leaveACommentSuccessDescription")}
            </p>
          </React.Fragment>
        )}
      </form>
    </SimpleModal>
  )
}

export { CommentModal }
