import * as React from "react"

import { lockScroll, restoreScroll, useRouteChange } from "@app/utils"

import { useMobileMenu } from "@app/features/gallery/store/hooks"
import { usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { useSharingModal } from "@app/features/sharing/store/hooks"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { useDownloadModal } from "@app/features/download/store/hooks"
import { useCommentsModal } from "@app/features/favorites/store"
import { useReviewModal } from "@app/features/review/stores"
import { useAuthorizationModal } from "@app/features/user/stores"
import { useLimitModal } from "@app/features/user/stores"

function useScrollLockController() {
  const { $isMobileMenuVisible, $isMobileSubmenuVisible, $hideMobileMenu } = useMobileMenu()
  const { $isPhotoCarouselVisible, $hidePhotoCarousel } = usePhotoCarousel()
  const { $isSharingModalVisible, $hideSharingModal } = useSharingModal()
  const { $isOnboardingVisible, $hideOnboarding } = useOnboarding()
  const { $isDownloadModalVisible, $hideDownloadModal } = useDownloadModal()
  const { isCommentModalVisible, hideCommentModal } = useCommentsModal()
  const { isReviewModalVisible, hideReviewModal } = useReviewModal()
  const { isAuthorizationModalVisible, hideAuthorizationModal } = useAuthorizationModal()
  const { isLimitModalVisible, hideLimitModal } = useLimitModal()

  const isScrollShouldBeLocked = React.useRef(false)

  const flags = [
    $isMobileMenuVisible,
    $isMobileSubmenuVisible,
    $isPhotoCarouselVisible,
    $isSharingModalVisible,
    $isOnboardingVisible,
    $isDownloadModalVisible,
    isCommentModalVisible,
    isReviewModalVisible,
    isAuthorizationModalVisible,
    isLimitModalVisible,
  ]

  const callbacks = [
    restoreScroll,
    $hideMobileMenu,
    $hidePhotoCarousel,
    $hideSharingModal,
    $hideOnboarding,
    $hideDownloadModal,
    hideCommentModal,
    hideReviewModal,
    hideAuthorizationModal,
    hideLimitModal,
  ]

  React.useEffect(() => {
    if (flags.some((flag) => Boolean(flag)) && !isScrollShouldBeLocked.current) {
      isScrollShouldBeLocked.current = true
      lockScroll()

      return
    }

    if (flags.every((flag) => !Boolean(flag)) && isScrollShouldBeLocked.current) {
      isScrollShouldBeLocked.current = false
      restoreScroll()

      return
    }
  }, [...flags])

  function handleRouteChange() {
    callbacks.forEach((callback) => callback())
  }

  useRouteChange(handleRouteChange)
}

export { useScrollLockController }
