import cx from "clsx"

import styles from "./textarea.module.scss"

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string
  name?: string
  register?: any
  placeholder?: string
}

const Textarea = ({ className, register, placeholder, name, ...props }: TextareaProps) => {
  return (
    <textarea
      name={name}
      ref={register}
      placeholder={placeholder}
      className={cx(styles["textarea"], className)}
      {...props}
    ></textarea>
  )
}

export { Textarea }
