import React from "react"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { useGlobalState } from "@app/contexts"
import { useGetFavoritePhotos } from "features/masonry/hooks"
import { useDownloadFavoritePhotos } from "features/download/hooks"

import { trackYandexMetrikaAction } from "@app/features/analytics/utils"

import { Back } from "ui/back"
import { Download, Picture } from "ui/icons"
import { Tooltip } from "ui/tooltip"
import { Container } from "ui/container"

import styles from "./header.module.scss"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

const Header = ({ favoritesListId, isDownloadEnabled, isFavoritesListOwner }) => {
  const [isContentLoading, setIsContentLoading] = React.useState(true)
  const router = useRouter()

  const downloadFavoritePhotos = useDownloadFavoritePhotos(favoritesListId)

  function handleGoBackClick() {
    const numberOfEntries = window.history.length
    const isPreviousPageExist = numberOfEntries > 1
    if (isPreviousPageExist) {
      router.back()
    } else {
      router.push("/")
    }
  }

  const $favoritePhotos = useGetFavoritePhotos()

  const isLikedPhotosExist = !!$favoritePhotos?.length

  React.useEffect(() => {
    setIsContentLoading(false)
  }, [$favoritePhotos])

  const i18n = useI18n()

  const {
    userAgent: { isMobile },
  } = useGlobalState()

  function handleDownloadFavoritePhotosClick() {
    trackYandexMetrikaAction(
      isMobile ? "click-favorites-download-photo-mobile" : "click-favorites-download-photo-desktop"
    )
    downloadFavoritePhotos()
  }

  return (
    <header className={styles["header"]}>
      <Container>
        <nav className={styles["navigation"]}>
          <Back
            text={i18n.t("common.back")}
            className={styles["back"]}
            page="favorites"
            onClick={handleGoBackClick}
          ></Back>
          <ul className={styles["actions"]}>
            {isDownloadEnabled && (
              <li className={styles["action"]}>
                {isLikedPhotosExist && (
                  <Tooltip text={i18n.t("common.downloadAllPhotos")} position="down-right">
                    <button onClick={handleDownloadFavoritePhotosClick}>
                      <IconWrapper
                        light={<Download width="18" height="18" />}
                        dark={<Download stroke="#fff" width="18" height="18" />}
                      />
                    </button>
                  </Tooltip>
                )}
              </li>
            )}
          </ul>
        </nav>
        <h1 className={styles["title"]}>{i18n.t("common.favorites")}</h1>
        <p className={styles["count"]}>
          <span className={styles["mark-icon"]}>
            <IconWrapper
              light={<Picture width="12" height="9" />}
              dark={<Picture width="12" height="9" stroke="#929292" />}
            />
          </span>
          {!isContentLoading && isLikedPhotosExist && $favoritePhotos.length}
          {!isContentLoading && !isLikedPhotosExist && 0}
        </p>
      </Container>
    </header>
  )
}

export { Header }
