import create from "zustand"

const useFavoriteListsStore = create((set) => ({
  favoriteLists: [],
  setFavoriteLists: (favoriteLists) => set(() => ({ favoriteLists })),

  addLikedPhoto: (likedPhoto) => set(({ favoriteLists }) => [...favoriteLists, likedPhoto]),

  likedPhoto: {},
  setLikedPhoto: (likedPhoto) => set(() => ({ likedPhoto })),
}))

export { useFavoriteListsStore }
