import { useFavoriteListsStore } from "features/favorites/store"

const setLikedPhotoSelector = (state) => state.setLikedPhoto
const likedPhotoSelector = (state) => state.likedPhoto

function useLikedPhoto() {
  const $setLikedPhoto = useFavoriteListsStore(setLikedPhotoSelector)
  const $likedPhoto = useFavoriteListsStore(likedPhotoSelector)

  return { $likedPhoto, $setLikedPhoto }
}

export { useLikedPhoto }
