import { customGroups } from "@app/features/products/utils"
import { GROUPS, IS_CUSTOMER_PRODUCT } from "@app/features/products/constants"

const formatProductGroups = (groups) => {
  // let newGroups = groups.map((group) => {
  //   return { ...group, ...GROUPS[group.handle] }
  // })

  const customerGroups = groups.length ? customGroups(groups, "PHOTOBOOK", "PHOTOBOOK_CUSTOMER", 3) : null
  const productGroups = IS_CUSTOMER_PRODUCT && groups.length ? customerGroups : groups

  const groupsActive = productGroups.filter((group) => {
    if (GROUPS[group.handle]) return { ...group }
  })
  let newGroups = groupsActive.map((group) => {
    return { ...group, ...GROUPS[group.handle] }
  })

  return newGroups
}

export { formatProductGroups }
