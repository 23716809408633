import { Button } from "../button"
import { Modal } from "../modal"

import cn from "clsx"

import styles from "./confirmation-modal.module.scss"
import { PropsWithChildren } from "react"

export type ConfirmationModalProps = PropsWithChildren<{
  open: boolean
  acceptText: string
  declineText: string
  onAccept: () => void
  onDecline: () => void

  className?: string
}>

const ConfirmationModal = ({
  open,
  acceptText,
  declineText,
  onAccept,
  onDecline,
  className = "",
  children,
}: ConfirmationModalProps) => {
  return (
    <Modal
      isCrossIconVisible={false}
      modalClassName={cn(styles["root"], className)}
      open={open}
      onClose={() => onDecline()}
    >
      <>
        <div className={styles["text"]}>{children}</div>
        <div className={styles["actions"]}>
          <Button className={styles["button"]} onClick={() => onAccept()}>
            {acceptText}
          </Button>
          <Button className={styles["button"]} skin="secondary" onClick={() => onDecline()}>
            {declineText}
          </Button>
        </div>
      </>
    </Modal>
  )
}

export { ConfirmationModal }
