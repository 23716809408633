import create, { State } from "zustand"

interface CommentModalStore extends State {
  isCommentModalVisible: boolean
  showCommentModal: () => void
  hideCommentModal: () => void
}

const useCommentModalStore = create<CommentModalStore>((set) => ({
  isCommentModalVisible: false,
  showCommentModal: () => set({ isCommentModalVisible: true }),
  hideCommentModal: () => set({ isCommentModalVisible: false }),
}))

const isCommentsModalVisibleSelector = (state: CommentModalStore) => state.isCommentModalVisible
const showCommentsModalSelector = (state: CommentModalStore) => state.showCommentModal
const hideCommentsModalSelector = (state: CommentModalStore) => state.hideCommentModal

function useCommentsModal() {
  const isCommentModalVisible = useCommentModalStore(isCommentsModalVisibleSelector)
  const showCommentModal = useCommentModalStore(showCommentsModalSelector)
  const hideCommentModal = useCommentModalStore(hideCommentsModalSelector)

  return { isCommentModalVisible, showCommentModal, hideCommentModal }
}

export { useCommentsModal }
