import React from "react"

import { useI18n } from "next-localization"

import { Modal } from "@app/ui/modal"
import { ProductsSection } from "@app/features/products/components/products-section"
import { productsModalActions } from "@app/features/products/hoc/products-modal-actions"

import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

import styles from "./download-products-modal.module.scss"

/**
 * Компонент отображающий список товаров печатной продукции в модальном окне
 *
 * @component
 * @example
 * return (
 *   <DownloadProductsModal isVisible={true} hide={() => setIsVisible(false)} />
 * )
 */
const DownloadProductsModal = ({ isVisible, hide }): JSX.Element => {
  const { t } = useI18n()

  return (
    <Modal isCrossIconVisible={true} open={isVisible} onClose={() => hide()} modalClassName={styles["root"]}>
      <div className={styles["content"]}>
        <ProductsSection
          title={t("productsModal.download.title")}
          description={t("productsModal.download.description")}
          onlyAvailable={false}
          position={"modal"}
        />
      </div>
    </Modal>
  )
}

export default productsModalActions(DownloadProductsModal, PRODUCTS_MODAL_TYPES.DOWNLOAD)
