import create from "zustand"

const AUTOHIDE_TIMEOUT = 3000
const ANIMATION_TIMEOUT = 200
let visibilityTimer = null
let animationTimer = null
function clearTimeouts() {
  clearTimeout(visibilityTimer)
  clearTimeout(animationTimer)
}

const useNotificationStore = create((set, get) => ({
  isNotificationVisible: false,
  notificationSettings: { type: null, text: null, image: null, link: "" },

  showNotification: async (settings) => {
    if (!get().isNotificationVisible) {
      clearTimeouts()
      set((state) => ({ isNotificationVisible: true, notificationSettings: { ...state, ...settings } }))
      visibilityTimer = setTimeout(() => {
        set(() => ({ isNotificationVisible: false }))
      }, AUTOHIDE_TIMEOUT)

      return
    }

    if (get().isNotificationVisible) {
      clearTimeouts()
      set(() => ({ isNotificationVisible: false }))

      animationTimer = setTimeout(() => {
        set((state) => ({ isNotificationVisible: true, notificationSettings: { ...state, ...settings } }))
        visibilityTimer = setTimeout(() => {
          set(() => ({ isNotificationVisible: false }))
        }, AUTOHIDE_TIMEOUT)
      }, ANIMATION_TIMEOUT)

      return
    }
  },

  hideNotification: () => set(() => ({ isNotificationVisible: false })),
}))

export { useNotificationStore }
