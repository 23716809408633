import { useI18n } from "next-localization"
import { useGlobalState } from "@app/contexts"

import { Button } from "@app/ui/button"
import { useCommentsModal } from "@app/features/favorites/store"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"

import styles from "./comment-button.module.scss"

export const CommentButton = () => {
  const { showCommentModal } = useCommentsModal()
  const i18n = useI18n()
  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const handleCommentButton = () => {
    trackYandexMetrikaAction(isMobile ? "click-comment-favorites-mobile" : "click-comment-favorites-desktop")
    showCommentModal()
  }

  return (
    <div className={styles["root"]}>
      <Button skin="secondary" onClick={() => handleCommentButton()}>
        {i18n.t("favoritesListPage.leaveACommentButton")}
      </Button>
    </div>
  )
}
