import * as React from "react"

import { setCSSVariable, useRouteChange } from "@app/utils"

function useSetHeadingsFont(
  fontFamily:
    | "CORMORANT_GARAMOND"
    | "MUSEO_SANS_CYRL"
    | "FORUM"
    | "JOST"
    | "NYGHT_SERIF"
    | "ROBOTO_CONDENSED"
    | "SPECTRAL" = "CORMORANT_GARAMOND"
) {
  React.useEffect(() => {
    switch (fontFamily) {
      case "CORMORANT_GARAMOND":
        setCSSVariable("--heading-font-family", "var(--font-family-cormorant_garamond)")
        setCSSVariable("--heading-font-weight", 400)
        break

      case "MUSEO_SANS_CYRL":
        setCSSVariable("--heading-font-family", "var(--font-family-museo_sans_cyrl)")
        setCSSVariable("--heading-font-weight", 300)
        break

      case "FORUM":
        setCSSVariable("--heading-font-family", "var(--font-family-forum)")
        setCSSVariable("--heading-font-weight", 300)
        break

      case "JOST":
        setCSSVariable("--heading-font-family", "var(--font-family-jost)")
        setCSSVariable("--heading-font-weight", 300)
        break

      case "ROBOTO_CONDENSED":
        setCSSVariable("--heading-font-family", "var(--font-family-roboto_condensed)")
        setCSSVariable("--heading-font-weight", 300)
        break

      case "SPECTRAL":
        setCSSVariable("--heading-font-family", "var(--font-family-spectral)")
        setCSSVariable("--heading-font-weight", 300)
        break

      default:
        setCSSVariable("--heading-font-family", "var(--font-family-cormorant_garamond)")
        setCSSVariable("--heading-font-weight", 400)
        break
    }
  }, [fontFamily])
}

function useSetTheme(value) {
  const THEME_DARK = "theme-dark"
  const THEME_LIGHT = "theme-light"

  useRouteChange((url: string) => {
    if (url.indexOf("/gallery/") <= -1 && url.indexOf("/favorites-list/") <= -1) {
      document.querySelector("body").classList.remove(THEME_DARK)
      document.querySelector("body").classList.add(THEME_LIGHT)
    }
  })

  React.useEffect(() => {
    document.querySelector("body").classList.remove(THEME_DARK)
    document.querySelector("body").classList.remove(THEME_LIGHT)
    document.querySelector("body").classList.add(`theme-${value}`)
  }, [value])
}

function useSetHeadingsSpacing(value) {
  React.useEffect(() => {
    setCSSVariable("--photo-spacing", `var(--photo-spacing-${value})`)
    setCSSVariable("--photo-spacing-container-mobile", `var(--photo-spacing-container-${value}-mobile)`)
  }, [value])
}

export { useSetHeadingsFont, useSetTheme, useSetHeadingsSpacing }
