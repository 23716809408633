const GROUPS = {
  PHOTOBOOK: {
    disabled: false,
    title: "Большая фотокнига",
    link: "product/book/",
    picture: "1.jpg",
    type: "photo-book",
  },
  MINIBOOK: {
    disabled: false,
    title: "Фотокнига в твердой обложке",
    link: "product/mini-book/",
    picture: "3.png",
    type: "mini-book",
  },
  CANVAS: {
    disabled: false,
    title: "Холст",
    link: "product/canvas/",
    picture: "2.jpg",
    type: "canvas",
  },
  PHOTO_SET: {
    disabled: false,
    title: "Набор фотографий",
    link: "product/photo-set/",
    picture: "6.jpg",
    type: "photo-set",
  },
  PHOTOBOOK_CUSTOMER: {
    disabled: false,
    title: "Книга из личных фото",
    link: "product/book-customer/",
    picture: "5.jpg",
    type: "photo-book-customer",
  },
  PHOTO: {
    disabled: true,
    title: "Фото, 15х20 см",
    link: "#",
    picture: "4.jpg",
    type: "photo",
  },
}

const IS_CUSTOMER_PRODUCT = false

const TRACKING_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  PRINTING: "PRINTING",
  DELIVERING: "DELIVERING",
  CANCELED: "CANCELED",
  ERROR: "ERROR",
}

export { GROUPS, IS_CUSTOMER_PRODUCT, TRACKING_STATUS }
