import { useFavoriteListsStore } from "features/favorites/store"

const selector = (state) => state.favoriteLists

function useGetFavoriteLists() {
  const favoriteLists = useFavoriteListsStore(selector)

  return favoriteLists
}

export { useGetFavoriteLists }
