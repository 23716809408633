import create, { State } from "zustand"

interface ReviewModalStore extends State {
  isReviewModalVisible: boolean
  showReviewModal: () => void
  hideReviewModal: () => void
}

const useReviewModalStore = create<ReviewModalStore>((set) => ({
  isReviewModalVisible: false,
  showReviewModal: () => set({ isReviewModalVisible: true }),
  hideReviewModal: () => set({ isReviewModalVisible: false }),
}))

const isReviewModalVisibleSelector = (state: ReviewModalStore) => state.isReviewModalVisible
const showReviewModalSelector = (state: ReviewModalStore) => state.showReviewModal
const hidePreviewModalSelector = (state: ReviewModalStore) => state.hideReviewModal

function useReviewModal() {
  const isReviewModalVisible = useReviewModalStore(isReviewModalVisibleSelector)
  const showReviewModal = useReviewModalStore(showReviewModalSelector)
  const hideReviewModal = useReviewModalStore(hidePreviewModalSelector)

  return { isReviewModalVisible, showReviewModal, hideReviewModal }
}

export { useReviewModal }
